import React, { useContext } from "react"
import { AppContext } from "../context/context"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FaWindowClose } from "react-icons/fa"
import { MdPermPhoneMsg } from "react-icons/md"
import * as styles from "./side-nav.module.scss"
import SubNav from "./SubNav"
const SideNav = ({
  sideNavItems,
  logo,
  prPortal,
  prReviews,
  enableBuyOnline,
  buyOnlineLink,
  location,
  phone,
  trackingPhone,
}) => {
  const {
    isNavOpen,
    handleCloseNav,
    whereWeServiceSlug,
    whereWeServiceActive,
  } = useContext(AppContext)
  const buyOnlineSlug =
    enableBuyOnline.value[0].codename === "yes" ? buyOnlineLink : null
  const [brand] = logo
  return (
    <aside
      className={
        isNavOpen ? `${styles.nav}` : `${styles.nav} ${styles.closeNav}`
      }
    >
      <div className={styles.sideNavHeader}>
        <img src={brand.fluid.src} alt="Logo" className={styles.logo} />
        <button onClick={handleCloseNav} className={styles.closeButton}>
          <FaWindowClose /> close
        </button>
      </div>

      <ul className={styles.links}>
        {sideNavItems.map(({ elements, id }, i) => {
          const { link_text, page, child_page, external_link } = elements
          if (child_page.value.length > 0) {
            return (
              <SubNav
                prReviews={prReviews}
                key={i + id}
                parent={page}
                kid={child_page}
                link_text={link_text}
                location={location}
              />
            )
          } else if (external_link.value.length > 0) {
            return (
              <li key={id}>
                <a
                  className={styles.link}
                  href={external_link.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link_text.value}
                </a>
              </li>
            )
          } else {
            const [links] = page.value
            const { slug } = links.elements
            if (
              prReviews.value[0].codename === "no" &&
              slug.value === "reviews"
            )
              return null
            if (links.__typename === "kontent_item_service_area") {
              return (
                <li key={id}>
                  <Link
                    onClick={handleCloseNav}
                    to={
                      whereWeServiceActive === "true"
                        ? `/${whereWeServiceSlug}/${slug.value}`
                        : `/${slug.value}`
                    }
                    activeClassName={styles.linkActive}
                    className={styles.link}
                  >
                    {link_text.value}
                  </Link>
                </li>
              )
            }
            return (
              <li key={id}>
                <Link
                  onClick={handleCloseNav}
                  to={slug.value === "/" ? `${slug.value}` : `/${slug.value}`}
                  activeClassName={styles.linkActive}
                  className={styles.link}
                >
                  {link_text.value}
                </Link>
              </li>
            )
          }
        })}
        {prPortal.value[0].codename === "yes" && (
          <li>
            <a
              href="/login"
              onClick={handleCloseNav}
              className={`${styles.link} ${
                location === "/login" && styles.linkActive
              }`}
            >
              Login
            </a>
          </li>
        )}
      </ul>
      <a href={`tel: ${trackingPhone}`} className={styles.phone}>
        <span>
          <MdPermPhoneMsg />
          {trackingPhone}
        </span>
      </a>
      {enableBuyOnline.value[0].codename === "yes" && (
        <AnchorLink
          className="accent-button"
          onAnchorLinkClick={handleCloseNav}
          to={`/${buyOnlineSlug}#bpco-container`}
          stripHash={true}
        >
          Buy Now
        </AnchorLink>
      )}
    </aside>
  )
}

export default SideNav
